import React, { useRef, useEffect } from 'react'

import videoCover from '../../images/video-cover.jpg'

import './styles.css'

const VideoCover = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.controls = false
    videoElement.setAttribute('muted', '')

    const playVideo = () => videoElement.play()
    playVideo()

    document.addEventListener('click', playVideo)

    return () => {
      window.removeEventListener('click', playVideo)
    }
  }, [])

  return (
    <video
      ref={videoRef}
      className="video-cover"
      preload="auto"
      poster={videoCover}
      playsInline
      autoPlay
      loop
      muted
    >
      <source src={'/videos/plantcanal.mp4'} type="video/mp4" />
    </video>
  )
}

export default VideoCover