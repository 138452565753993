import React, { useEffect, useState } from 'react'
import Typewriter from 'typewriter-effect';
import { MOBILE_SIZE } from '../../constants';

import './styles.css'

const DESKTOP_TEXT = 'Somos una empresa familiar<br />avalada por más de cuatro décadas<br />de experiencia y formación continua<br />en el cultivo de planta hortícola'
const MOBILE_TEXT = 'Somos una empresa<br />familiar avalada por más<br />de cuatro décadas de<br />experiencia y formación<br />continua en el cultivo de<br />planta hortícola'

const IntroText = ({ data }) => {
  const desktopText = data?.presentation_desktop?.text || DESKTOP_TEXT
  const mobileText = data?.presentation_mobile?.text || MOBILE_TEXT

  const [text, setText] = useState(mobileText)
  
  useEffect(() => {
    const handleSetText = () => {
      if (window === 'undefined') return
  
      if (window?.innerWidth > MOBILE_SIZE) {
        setText(desktopText)
        return
      }
  
      setText(mobileText)
    }

    new Typewriter('#into-text', { autoStart: true });
    handleSetText()
  }, [desktopText, mobileText])

  return (
    <div id="into-text" className="intro-text">
      <Typewriter
        options={{
          strings: text,
          autoStart: true,
          delay: 100
        }}
      />
    </div>
  )
}

export default IntroText