import React from 'react'

import './styles.css'

const Home = ({ children }) => {
  return (
    <div className="home">
      <div className="home__intro">
        {children}
      </div>
    </div>
  )
}

export default Home