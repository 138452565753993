import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Home from "../components/Home"
import VideoCover from "../components/VideoCover"
import ContactHome from "../components/ContactHome"
import IntroText from "../components/IntroText"

import '../styles/index.css'

const IndexPage = ({ data, location }) => {
  return (
    <Layout
      pathname={location?.pathname}
      data={data?.allPrismicHome?.nodes?.[0]?.data}
      isHome
    >
      <SEO />
      <VideoCover />
      <Home>
        <IntroText data={data?.allPrismicHome?.nodes?.[0]?.data} />
      </Home>
      <ContactHome key="home-contact" data={data?.allPrismicDireccion?.nodes?.[0]?.data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicDireccion {
      nodes {
        data {
          address {
            html
            text
            raw
          }
          title {
            html
            text
            raw
          }
        }
      }
    }
    allPrismicHome {
      nodes {
        data {
          metodos_texto {
            html
            text
            raw
          }
          metodos_variedad_a {
            html
            text
            raw
          }
          metodos_variedad_b {
            html
            text
            raw
          }
          metodos_variedad_c {
            html
            text
            raw
          }
          nosotros_image {
            alt
            copyright
            url
            thumbnails
          }
          nosotros_texto {
            html
            text
            raw
          }
          nosotros_values {
            html
            text
            raw
          }
          presentation_desktop {
            html
            text
            raw
          }
          presentation_mobile {
            html
            text
            raw
          }
          servicios_description {
            html
            text
            raw
          }
          servicios_side_text {
            html
            text
            raw
          }
          servicios_subtitle_a {
            html
            text
            raw
          }
          servicios_subtitle_b {
            html
            text
            raw
          }
          servicios_title {
            html
            text
            raw
          }
          titulo {
            html
            text
            raw
          }
        }
      }
    }
  }
`

export default IndexPage
