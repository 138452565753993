import React, { useState, useEffect } from 'react'
import { Link } from "gatsby";

import { getAnimation } from '../../utils'

import './styles.css'

const Bubble = ({ showContact }) => {
  const getClassName = (showContact) => {
    if (showContact) return "contact__bubble contact__bubble--hide"
    return "contact__bubble"
  }

  return (
    <div className={getClassName(showContact)} {...getAnimation(400)}>
      <span>¿En que puedo<br />ayudarte?</span>
    </div>
  )
}

const SmallBubble = () => (
  <div className="contact__bubble contact__bubble--small" {...getAnimation(600)}>
    <span>Hola</span>
  </div>
)

const ContactBubble = ({ showContact }) => {
  const getClassName = (showContact) => {
    if (showContact) return "contact__bubble contact__bubble--contact contact__bubble--contact--show"
    return "contact__bubble contact__bubble--contact"
  }

  return (
    <Link className={getClassName(showContact)} to="/contacto">
      <span>contáctanos</span>
    </Link>
  )
}

const Contact = () => {
  const [showContact, setShowContact] = useState(false)

  useEffect(() => {
    let showContactButton = setTimeout(() => setShowContact(true), 4000);

    return () => clearTimeout(showContactButton)
  }, [])

  return (
    <div
      className="contact" {...getAnimation(500)}>
      <Bubble {... { showContact }} />
      <SmallBubble />
      <ContactBubble {... { showContact }} />
      <div className="contact__image">
        <img src={'images/nosotros-white.png'} alt="Contacto" />
      </div>
      <address className="contact__address">
        Ctra. Chella-Bolbaite, s/n.<br/>
        46821, Chella (Valencia)<br/>
        962 220 239 / 665 032 552<br/>
        <a href="mailto:info@plantcanal.com">info@plantcanal.com</a>
      </address>
    </div>
  )
}

const ContactHome = ({ data }) => {
  return (
    <div className="contact-home">
      <div className="contact-home__wrapper">
        <Contact {...{ data }} />
      </div>
    </div>
  )
}

export default ContactHome